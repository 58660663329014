<template>
  <div>
    <b-card no-title>
      <b-row>
        <b-col
            xs="12"
            class="mx-auto"
        >
          <b-card-title>
            {{ $t('card.title.modify.option') }}
          </b-card-title>
        </b-col>
      </b-row>
      <b-card-text>
        {{ $t('card.description.modify.option') }}
      </b-card-text>
    </b-card>
    <b-overlay :show="loading">
      <b-card-code>
        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group
                    :label="$t('options.'+formData.title)"
                    :description="$t('options.'+formData.description)"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('options.'+formData.title)"
                      rules=""
                      vid="value"
                  >

                    <b-input-group v-if="formData.type !== 'editor'">

                      <b-form-input v-if="formData.type === 'integer'"
                                    v-model="formData.value"
                                    type="number"
                                    :state="errors.length > 0 ? false:null"
                                    :placeholder="formData.title"
                      />

                      <b-form-input v-if="formData.type === 'text'"
                                    v-model="formData.value"
                                    :state="errors.length > 0 ? false:null"
                                    :placeholder="formData.title"
                      />

                      <b-form-input v-if="formData.type === 'date'"
                                    v-model="formData.value"
                                    type="text"
                                    placeholder="ÉÉÉÉ-HH-NN"
                                    autocomplete="off"
                                    :formatter="formatDateAllowedSymbols"
                                    show-decade-nav
                                    required
                      />

                      <b-form-input v-if="formData.type === 'time'"
                                    v-model="formData.value"
                                    type="time"
                                    :state="errors.length > 0 ? false:null"
                                    :placeholder="formData.title"
                                    :formatter="formatTimeAllowedSymbols"
                      />

                      <flat-pickr v-if="formData.type === 'datetime'"
                                  v-model="formData.value"
                                  class="form-control"
                                  :config="flatPickRConfig"
                      />

                      <b-input-group-append v-if="formData.type === 'date'">
                        <b-form-datepicker
                            v-model="formData.value"
                            size="sm"
                            show-decade-nav
                            button-only
                            right
                            :locale="$i18n.locale"
                            v-bind="labels[$i18n.locale] || {}"
                        />
                      </b-input-group-append>

                      <b-input-group-append v-if="formData.type === 'time'">
                        <b-form-timepicker
                            v-model="formData.value"
                            size="sm"
                            button-only
                            right
                            :locale="$i18n.locale"
                            v-bind="labels[$i18n.locale] || {}"
                        />
                      </b-input-group-append>

                      <v-select v-if="formData.type === 'select'"
                                v-model="formData.value"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="title"
                                :options="valueOptions"
                      />

                      <b-form-radio-group v-if="formData.type === 'radio'"
                                          id="value-radio-group"
                                          v-model="formData.value"
                                          :options="valueOptions"
                      />

                      <b-form-checkbox v-if="formData.type === 'checkbox'"
                                       v-model="formData.value"
                                       :state="errors.length > 0 ? false:null"
                      >
                        {{ formData.title }}
                      </b-form-checkbox>

                      <b-form-textarea v-if="formData.type === 'textarea'"
                                       v-model="formData.value"
                      ></b-form-textarea>

                    </b-input-group>

                    <div v-if="formData.type === 'editor'">
                      <editor :value="formData.value" @input="valueUpdated"/>
                    </div>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- submit button -->
              <b-col class="mt-1">
                <b-button
                    variant="primary"
                    type="submit"
                    class="float-right"
                    @click.prevent="submitForm"
                >
                  {{ $t('label.button.save') }}
                </b-button>
                <cancel-and-g-o-t-o-previous-page class="mr-2"/>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-code>
    </b-overlay>
    <b-overlay
        :show="tabsLoading"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="sm"
    >
      <b-card-code>
        <tabs
            ref="tabs"
            entity-type="App\Models\Option"
            :entity-id="parseInt(this.$route.params.id)"
        />
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BOverlay,
  BFormTextarea,
  BLink,
  BInputGroup,
} from 'bootstrap-vue'
import {
  email,
  required,
} from '@validations'
import vSelect from 'vue-select'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import tabs from '@/views/pages/_components/_tabs/_option/update.vue'
import labels from '@/libs/datepicker/labels'
import {formatDateAllowedSymbols, formatTimeAllowedSymbols} from "@core/utils/filter";
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css';
import {Hungarian} from 'flatpickr/dist/l10n/hu.js'
import editor from "@/views/pages/_components/_helper/editor.vue";

export default {
  components: {
    editor,
    BLink,
    BFormTextarea,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BCardText,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BOverlay,
    BInputGroup,
    vSelect,
    cancelAndGOTOPreviousPage,
    tabs,
    flatPickr,
  },
  data() {
    return {
      role: 'option',
      formData: {
        id: null,
        type: '',
        title: '',
        key: '',
        value: '',
        options: '',
        description: '',
      },
      valueOptions: [],
      flatPickRConfig: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        locale: Hungarian,
        allowInput: true
      },
      labels,
      formatDateAllowedSymbols,
      formatTimeAllowedSymbols,
      email,
      tabsLoading: false,
      loading: false,
      required,
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      this.$store.dispatch('fetchOption', this.$route.params.id).then(response => {
        this.formData = response.data.entity
        this.formData.id = this.$route.params.id

        if (response.data.entity.type === 'select' || response.data.entity.type === 'radio') {
          if (this.isJsonValid(response.data.entity.options)) {
            this.valueOptions = JSON.parse(response.data.entity.options)
          } else {
            this.$store.dispatch(response.data.entity.options).then(response => {
              this.valueOptions = response
            })
          }
        }

        if (response.data.entity.type === 'select') {
          Object.keys(this.valueOptions).forEach(key => {
            if (this.valueOptions[key].value === response.data.entity.value) {
              this.formData.value = this.valueOptions[key];
            }
          })
        }
        this.loading = false
      }).catch(error => {
        this.$helpers.showErrorToast(error.response.data.message)
        this.$acl.canListPermission(this.role)
            ? this.$router.push({name: 'options'})
            : this.$router.go(-1)
      }).finally(() => {
        this.loading = false
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // clone formData
          let fd = JSON.parse(JSON.stringify(this.formData))
          if (this.formData.type === 'select') {
            fd.value = this.formData.value.value
          }

          this.$store.dispatch('updateOption', fd).then(() => {
            this.$helpers.showSuccessToast()

            this.initData()
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
    valueUpdated(value) {
      this.formData.value = value
    },
  }
}
</script>
